import React from 'react'

export function MySavedPosts() {
    return (
        <div className="page_bottom">
            <div style={{marginLeft: "400px"}}>No post yet</div>
            {/* <div className="post">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxtkzEAP67zPI62rt3KkHNtmzf57ocLYdEgA&usqp=CAU" alt="" />
            </div>
            <div className="post">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxtkzEAP67zPI62rt3KkHNtmzf57ocLYdEgA&usqp=CAU" alt="" />
            </div>
            <div className="post">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxtkzEAP67zPI62rt3KkHNtmzf57ocLYdEgA&usqp=CAU" alt="" />
            </div>
            <div className="post">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxtkzEAP67zPI62rt3KkHNtmzf57ocLYdEgA&usqp=CAU" alt="" />
            </div>
            <div className="post">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxtkzEAP67zPI62rt3KkHNtmzf57ocLYdEgA&usqp=CAU" alt="" />
            </div> */}
        </div>
    )
}