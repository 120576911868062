export const Definer = {
	general_err1: 'att: Something went wrong!',

	auth_err1: "att: Pleace login first!",

	input_err1: 'att: Please fulfil all inputs!',
	input_err2: "att: Please insert JPG, JPEG, PNG formats only!",
	input_err3: 'att: Message is empty',

	// Post related errors
	post_error1: "There was an error creating the photo post!",
};